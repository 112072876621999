/* 
Home Page Start
*/

/*
 Home Page End
*/

/* Site Home Page Card  Start*/
.site-card {
  margin-top: 130px;
  box-shadow: 1px 10px 50px #00000024;
  color: white;
  text-align: center;
  font-size: 1.1rem;
  border-radius: 15px;
  -webkit-perspective: 800px;
          perspective: 800px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin-bottom: 50px;
  border-width: 0px;
  background-color:aliceblue;
}
.site-card .card-header {
  padding: 0px;
  margin: 0px;
  height: 75px;
  position: relative;
  border: 1px solid transparent;
  background-color: transparent;
}
.site-card .card-header img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: absolute;
  top: -50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-25%);
          transform: translateX(-50%) translateY(-25%);
  border: 8px solid transparent;
  box-shadow: 0 0 20px #00000033;
  transition: all 0.5s linear;
  -webkit-animation: flip 2s linear infinite alternate;
          animation: flip 2s linear infinite alternate;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: translateX(-50%) translateY(-25%) scale(1);
            transform: translateX(-50%) translateY(-25%) scale(1);
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-30%) scale(1.1);
            transform: translateX(-50%) translateY(-30%) scale(1.1);
  }
}
@keyframes flip {
  0% {
    -webkit-transform: translateX(-50%) translateY(-25%) scale(1);
            transform: translateX(-50%) translateY(-25%) scale(1);
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-30%) scale(1.1);
            transform: translateX(-50%) translateY(-30%) scale(1.1);
  }
}
.site-card .card-header img:hover {
  width: 200px;
  height: 200px;
  cursor: pointer;
  border: 0px solid transparent;
}
.site-card .card-body {
  padding: 0px;
}
.site-card .card-body .card-title {
  margin-top: 30px;
  font-size: 1.6rem;
  font-weight: 900;
  color: #042e35;
  letter-spacing: 3px;
  -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: #000000;
}
.site-card .card-body .card-title:hover {
  font-size: 1.7rem;
}

.site-card .card-body button {
padding:5px 20px;
  color: #ffffff;
  background-color: #042e35;

}

.site-card .card-body .mail:hover {
  color: #0a0a0a;
}
.site-card .card-body .job,
.site-card .card-body .cellno,
.site-card .card-body .mail {
  color: #042e35;
  text-decoration: none;
}
.site-card .social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.site-card .social-links .social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  text-decoration: none;
  margin: 0 13px 30px 0;
}
.site-card .social-links .social-icon:last-child {
  margin-right: 0;
}
.site-card .card .social-links .social-icon:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  text-decoration: none;
}


/* Site Home Page Card  End*/
/*
Login & SignUp Pages
*/

.log-wrapper {
  margin: "32px auto 37px";
  max-width: "530px";
  background-color: "#fff";
  padding: "30px";
  border-radius: "10px";
  box-shadow: "0px 0px 10px 10px rgba(0,0,0,0.15)";
}

/*
Login & SignUp Pages
*/
@font-face {
  font-family: "Jameel Noori Nastaleeq";
  src: url("http://www.fontsaddict.com/fontface/jameel-noori-nastaleeq.ttf");
}

@font-face {
  font-family: "Jameel Noori Nastaleeq";
  src: url(/static/media/JameelNooriNastaleeqRegular.4b37da11.ttf);
}

.sba {
  position: relative;
  color: black;
  font-family: "Jameel noori nastaleeq", serif;
  font-weight: 600;
  unicode-bidi: embed;
  text-align: right;
  direction: rtl;
  font-size: 20px;
  max-width: 8.3in;
  max-height: 11.7in;
}
.sba .studata div {
  padding-bottom: 5px;
}
.sba img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

